@import './src/scss/variables';
@import './src/scss/app';

.widget__piano {
    flex-direction: row;
    justify-content: center;
}


.piano-container {
    position: relative;
    display: flex;
    justify-content: center;

  .white {
    display: flex;
    font-size: .8rem;
    height: 135px;

    .key {
      cursor: pointer;
      padding-top: 95px;
      text-align: center;
      border: 1px solid black;
      background-color: white;
      margin: 0;
      flex: 1 1 auto;
      align-self: stretch;
      min-width: 32px;
      max-width: 32px;
      border-bottom-left-radius: 6px;
      border-bottom-right-radius: 6px;
      margin-left: -1px;

      transform: scaleY(1);
      transform-origin: top;
      transition: transform .1s ease;

      span {
        display: block;
      }

      &.active {
        background-color: lightgrey !important;
        transform: scaleY(.97);
      }

      &.ng-add-active {
        //transition: height 1s ease-in;
      }

      //&:hover {
      //  background-color: #eaeaea;
      //}
    }


  }

  .black {
		pointer-events: none;
    position: absolute;
    gap: calc(34px / 2 - 1px);
    left: 22px;
    top: 0;
    display: flex;
    width: 100%;
    height: 90px;
    font-size: .6rem;
    text-align: center;

    .key {
      cursor: pointer;
      padding-top: 40px;
      pointer-events: auto;
      border: 0px;
      background-color: black;
      flex: 1 1 auto;
      align-self: stretch;
      min-width: 15px;
      max-width: 15px;
      border-bottom-left-radius: 4px;
      border-bottom-right-radius: 4px;
      color: white;

      transform: scaleY(1);
      transform-origin: top;
      transition: transform 0.1s ease;

      &.active {
        background-color: grey !important;
        transform: scaleY(0.97);
      }

      //&:hover {
      //  background-color: #252525;
      //}

      &.invisible {
        visibility: hidden;
      }
    }
  }
}

.app__content {
  width: 100%;
  display: flex;
  justify-content: center;
  text-align: center;
}

.history {
  overflow-wrap: break-word;
  margin: 0;
  width: 288px;
  height: 300px;
  letter-spacing: 1px;
  text-align: left;
  overflow-y: scroll;
  position: relative;
  z-index: 2;
  .correct {
    color: green;
  }
  .active {
    background-color: lightgrey;
  }
  .new-line {
    display: block;
  }
}

.has-sample {
  &::placeholder {
    color: #63687a
  }
  &:focus {
    &::placeholder {
      opacity: 0;
    }
  }
}

.samples-popup {
  ul.submenu__list {
    height: 200px;
    overflow-y: auto;
  }
}